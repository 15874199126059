@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    @font-face {
        font-family: Roboto;
        font-weight: 300;
        src: url(../public/fonts/Roboto-Light.ttf);
    }
    @font-face {
        font-family: Roboto;
        font-weight: 400;
        src: url(../public/fonts/Roboto-Regular.ttf);
    }
    @font-face {
        font-family: Roboto;
        font-weight: 500;
        src: url(../public/fonts/Roboto-Medium.ttf);
    }

    @layer base {
        a {
            @apply text-fluorgreen;
            @apply hover:underline;
        }
    }

    html {
        @apply bg-darkpetrol text-white;
    }
}
.button-subtitle {
    display: none;
}
.date-filter-wrapper {
    position: relative;
    align-items: bottom;
}
.date-filter-wrapper::after {
    content: '';
    border: solid;
    border-width: 0 1.5px 1.5px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(45deg);
    position: absolute;
    right: 8px;
    top: 50%;
    margin-top: -6px;

    @apply text-lightgray;
}
